export default {
    "props": {
        "containerProps": {
            "maxWidth": "xl",
        },
        "containerGridProps": {
            "container": true,
            "spacing": 0,
        },
        "itemLeftProps": {
            "item": true,
            "xs": 12,
            "md": 6,
        },
        "itemRightProps": {
            "item": true,
            "xs": true,
        },
        "siteByProps": {
            "variant": "bodySmall",
            "color": "textSecondary",
        },
        "copyrightTextProps": {
            "variant": "bodySmall",
            "color": "textSecondary",
        },
        "copyrightContainerMenuSx": {
            "display": "block",
        },
        "copyrightMenuProps": {
            "variant": "bodySmall",
            "color": "textSecondary",
            "underline": "hover",
        },
        "_footerMenus": [
            {
                "id": "link1",
                "name": "Term of Use",
                "url": "#",
            },
            {
                "id": "link2",
                "name": "Privacy Policy & Notice",
                "url": "#",
            }
        ],
    },
    "sx": (theme) => ({
        // "border-top": "1px solid black",
        "& .myaccountFooter-grid": {
            "direction": "column",
            "border-top": `1px solid ${theme.palette.grey.grey3}`,
            "paddingTop": theme.spacing(3),
            [theme.breakpoints.up('md')]: {
                "direction": "row",
                "paddingTop": theme.spacing(4),
            },
        },
        "& .myaccountFooter-grid-itemLeft": {

        },
        "& .myaccountFooter-grid-itemRight": {
            "display": "flex",
            "flexDirection": "row",
            [theme.breakpoints.up('md')]: {
                "justifyContent": "flex-end",
            },
        },
        "& .myaccountFooter-siteBy": {
            "marginRight": theme.spacing(1),
        },
        "& .icon-starberry-logotype": {
            "display": "inline-block",
            "color": "black",
            "width": "71px",
            "height": "17px",
            "& svg": {
                "opacity": 0.75,
                "&:hover": {
                    "opacity": 1
                }
            }
        },
        "& .copyrightMenu": {
            "borderRight": "1px solid rgba(0, 0, 0, 0.2)",
            "paddingRight": "5px",
            "marginRight": "5px",
            "&:last-child": {
                "borderRight": "none",
            },
        },
    }),
}
