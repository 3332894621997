import React from 'react'
import { withSubtheme } from '@starberryComponentsMui';
import MyAccountFooter from '@myaccount/footer';
import defaults from './ContentDefaults';


const ContentSubtheme = withSubtheme(props => {
    const {
        className,
        children
    } = props

    return(
        <main className={className}>
            <div className={"mainContent-wrapper"}>
                <div className={"mainContent-toolbar"} />
                {children}
            </div>
            <MyAccountFooter />
        </main>
    )
}, 'mainContent', defaults)

const MyaccountContent = React.memo((props) => {
    const {
        children,
    } = props

    return (
        <ContentSubtheme>
            {children}
        </ContentSubtheme>
    )
})

export default MyaccountContent
