export default {
    "props": {

    },
    "sx": (theme) => ({
        "display": "flex",
        "flexDirection": "column",
        "width": "100%",
        "flexGrow": 1,
        "padding": theme.spacing(theme.myAccountContentPTmobile, 0, (theme.myAccountContentPBmobile + 8), 0),
        [theme.breakpoints.up('sm')]: {
            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, (theme.myAccountContentPBtablet + 8), 0),
        },
        [theme.breakpoints.up('md')]: {
            // "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
        },
        [theme.breakpoints.up('lg')]: {
            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
        },
        "& .mainContent-wrapper": {
            "flexGrow": 1,
        },
        "& .mainContent-toolbar": theme.mixins.toolbar,
    }),
}