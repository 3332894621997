export default {
    "props": {
        "appBar": {
            "position": "fixed",
            "color": "inherit",
        },
        "burgerButtonIcon": {
            // "setIcon": "mobileMenuIcon", // default is burger icon
            "setIcon": "logoIcon", // default is burger icon
        },
        "burgerButton": {
            "color": "primary",
            "aria-label": "open drawer",
            "edge": "start",
            "disableRipple": true,
            "sx": {
                "py": 0,
                "marginRight": "0!important",
                "&:hover": {
                    "background": "transparent",
                },
                "& svg": {
                    "width": "auto",
                    "height": "48px",
                },
            },
        },
        "appBarTitle": {
            "variant": "bodyMedium",
            "component": "h6",
            "noWrap": true,
        },
        "menusBottomFixed": [
            {
                "id": 1,
                "hide": false,
                "name": "Dashboard",
                "icon": {
                    "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                    "props": {
                        "color": "primary",
                        "fontSize": "small",
                    },
                },
                "link": "/",
            },
            {
                "id": 2,
                "hide": false,
                "name": "Alert",
                "icon": {
                    "name": "inboxIcon",
                },
                "link": "/my-property",
            },
            {
                "id": 3,
                "hide": false,
                "name": "Calendar",
                "icon": {
                    "name": "calendarIcon",
                },
                "link": "/calendar",
            },
            {
                "id": 4,
                "hide": false,
                "name": "Search",
                "icon": {
                    "name": "searchIcon",
                },
                "link": "/calendar",
            },
            {
                "id": 5,
                "hide": false,
                "name": "Menu",
                "icon": {
                    "name": "mobileBurgerIcon",
                },
                "link": "#",
                "funcName": "menu",
            },
        ],
        "menuListItemMobile": {
            // "divider": true,
        },
        "appBarSearch": {
            "variant": "outlined",
            "placeholder": "Search",
            "inputProps": { 'aria-label': 'search' }
        },
        "userDropdown": {
            "variant": "text",
            "color": "primary",
            "disableRipple": true,
            "aria-label": "account of current user",
            "aria-haspopup": true,
        },
        "appBarMobile": {
            "position": "fixed",
            "color": "inherit",
        },
    },
    "sx": (theme) => ({
        "& .appBar": {
            "boxShadow": "none",
            "borderBottom": `1px solid ${theme.palette.divider}`,
            [theme.breakpoints.up('md')]: {
                "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                "marginLeft": `${theme.myAccountSetDrawerWidth}px`,
                // "marginRight": `${theme.myAccountSetDrawerWidth}px`, // Enable this if Drawer is positioned right
            },
            [theme.breakpoints.up('xl')]: {
                "width": `calc(100% - ${theme.myAccountSetDrawerWidthXL}px)`,
                "marginLeft": `${theme.myAccountSetDrawerWidthXL}px`,
            },
        },
        "& .appBar-grow": {
            [theme.breakpoints.up("md")]: {
                "flexGrow": 1,
            }
        },
        "& .appBar-sectionDesktop": {
            "display": "none",
            [theme.breakpoints.up("md")]: {
                "display": "flex",
                "alignItems": "center",
            },
        },
        "& .appBar-sectionMobile": {
            "display": "flex",
            [theme.breakpoints.up("md")]: {
                "display": "none",
            },
        },
        "& li.MuiButtonBase-root": {
            "& > button": { // This should be the icon in the dropdown list
                "marginRight": theme.spacing(1),
            },
            "& > .appBar-notification": { // This should be the icon in the dropdown list
                "marginRight": theme.spacing(1),
            },
            "& > a": {
                "flexGrow": 1,
            },
        },
        "& .appBar-notification": {
            "color": "#000000",
            "& .appBar-badge": {
                "color": "#fff",
                "height": "23px",
                "minWidth": "24px",
                "borderRadius": "100%",
                "border": "2px solid #fff",
                "backgroundColor": theme.palette.primary.main,
            },
        },
        "& .appBar-burgerButton": {
            "marginRight": theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                "display": "none",
            },
        },
        "& .appBar-appBarTitle": {
            "marginLeft": "auto",
            "marginRight": "auto",
            [theme.breakpoints.up("md")]: {
                "display": "none",
            },
        },
        "& .appBar-menuListItemMobile": {
            "justifyContent": "center",
            "opacity": 0.4,
            "background": "none",
            "&.Mui-selected": {
                "opacity": 1,
                "background": "none",
            },
            "& .appBar-menuListItemMobile-divider": {
                "borderColor": "black",
            },
        },
        "& .appBar-menuListItemIconMobile": {
            "color": theme.palette.primary.contrastText,
            "minWidth": "inherit",
        },
        "& .appBar-userDropdown": {
            "fontFamily": theme.secondaryFontFamily,
            "margin": theme.spacing(0, 3.25),
            "paddingLeft": theme.spacing(1),
            "paddingRight": theme.spacing(1),
            "color": theme.palette.grey.grey1,
            "textTransform": "capitalize",
            "&:hover": {
                "background": "none",
            },
            "& .appBar-label": {
                "fontSize": theme.bodyMediumSize,
                "fontWeight": theme.typography.fontWeightBold,
                "color": theme.palette.primary.dark,
            },
            "& .appBar-text": {
                "&:hover": {
                    "backgroundColor": "transparent",
                    "color": theme.palette.secondary.main,
                },
            },
        },
        "& .appBar-appBarMobile": {
            "boxShadow": "none",
            "backgroundColor": theme.palette.primary.main,
            "border": "none",
            "width": "100%",
            "top": 'auto',
            "bottom": 0,
        },
        "& .appBar-userAvatar": {
            "textTransform": "uppercase",
        },
    }),
}
