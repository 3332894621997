export default {
    "props": {
        "setAnchor": "right",
        "listContainer": {
        },
    },
    "sx": {
        "& .customSidebarDrawer-paper": {

            "& .customSidebarDrawer-list": {
                "width": 300,
            },
            "& .customSidebarDrawer-fullList": {
                "width": 'auto',
            },
        },
    },
    "subthemes": {
        "menuListIconsTabs": {
            "sx": (theme) => ({
                "& .menuListIconsTabs": {
                    "padding": 0,
                    "margin": 0,
                    // "marginLeft": `-${theme.gridItemPmobile}`,
                    // "marginRight": `-${theme.gridItemPmobile}`,
                    // [theme.breakpoints.up('sm')]: {
                    //     "marginLeft": `-${theme.gridItemPtablet}`,
                    //     "marginRight": `-${theme.gridItemPtablet}`,
                    // },
                    // [theme.breakpoints.up('md')]: {
                    //     "marginLeft": 0,
                    //     "marginRight": 0,
                    // },
                },
                "& .menuListIconsTabs-tab": {
                    "fontWeight": theme.typography.fontWeightBold,
                    "textTransform": "none",
                    "minHeight": "58px",
                    "borderBottom": `1px solid ${theme.palette.divider}`,
                    "paddingTop": theme.spacing(1.5),
                    "paddingBottom": theme.spacing(1.5),
                    "paddingLeft": theme.gridItemPmobile,
                    "paddingRight": theme.gridItemPmobile,
                    "opacity": 1,
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.bodyMedium,
                        "lineHeight": theme.lineHeightBMedium,
                        "paddingLeft": theme.gridItemPtablet,
                        "paddingRight": theme.gridItemPtablet,
                    },
                    [theme.breakpoints.up('md')]: {
                        "padding": theme.spacing(1.5,0),
                    },
                    "&.menuListIconsTabs-tab-wrapper": {
                        "display": "flex",
                        "flexDirection": "row",
                        "alignItems": "center",
                        "justifyContent": "flex-start",
                    },
                },
            }),
        },
    },
}
