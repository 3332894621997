export default {
    "props": {
        "btnLabelProps": "",
        "maxDialogW": "sm",
        "modalTitle": "Logout",
        "modalSubTitle": "Are you sure you want to logout?",
        "btnProps": {},
        "dialogContentProps": {
            "sx": (theme) => ({
                "paddingBottom": 0
            })
        }
    },
    "sx": {
    }
}
