import React, { useEffect } from 'react'
import {
    MYACCOUNT_LIGHT,
    // WEBSITE_URL
} from "@myaccountUrls";
import { withSubtheme } from '@starberryComponentsMui';

import { StarberryIcons } from '@myaccount/icons';
import { LinkItem } from '@myaccount/helper/myaccountLink';

// import Link from '@mui/material/Link';

import { 
    AppBar, 
    IconButton, 
    Toolbar,
    Button,
    Avatar as UserAvatar,
    Menu,
    MenuItem,
    Badge,
    Typography
} from '@mui/material';
import DefineIconMenu from '@myaccount/iconMenuList/DefineIconMenu';
// import Box from '@mui/material/Box';

import { useAuthState } from "@myaccountServices";
import { goBack } from "@myaccount/utils";
import SidebarDrawerComponent from '@myaccount/customSidebarDrawer';
import { MYACCOUNT_INBOX, MYACCOUNT_PROFILE } from "@myaccountUrls";

import defaults from './AppBarDefaults';
import clsx from "clsx"
import { navigate } from 'gatsby';
import LogoutDialog from '@myaccount/user/logoutDialog';

import { useSelector } from 'react-redux';

const AppBarComp = withSubtheme(props => {
    const { state, services } = useAuthState();
    const [open, setOpen] = React.useState(false);

    const user = useSelector(state => state.profile);

    React.useEffect(() => {
      if (services.isAuthenticated() && !MYACCOUNT_LIGHT)
          services.getPendingActionsCount();
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        className,
        pageTitle,
        pageLeftIcon,
        pageRightDrawer,
        pageRightCustomComponent,
        handleDrawerToggle,

        appBar,
        burgerButtonIcon,
        burgerButton,
        appBarTitle,
        menusBottomFixed,
        menuListItemMobile,
        // appBarSearch,
        userDropdown,
        appBarMobile,
        rest
    } = props;

    const pendingItemsCount = state?.pendingItemsCount || 0;
    const pageRightDrawerContent = pageRightDrawer || state?.pageRightDrawer || null;
    const pageRightCutomComponentContent = pageRightCustomComponent || state?.pageRightCustomComponent || null;
    const pageLeftIconState = pageLeftIcon || state?.pageLeftIcon || null;

    const DefineBurgerMenu = (props) => {
        const { setIcon } = props;

        return <StarberryIcons iconName={setIcon} />
    }

    // Profile dropdown menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = (event) => {
        event.preventDefault();
        // services.logout();
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
        setOpen(true);
    };

    const redirectToProfilePage = () => {
        navigate('/profile');
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}><LinkItem to={MYACCOUNT_PROFILE} color="inherit">Profile</LinkItem></MenuItem>
            <MenuItem onClick={(event) => {handleLogout(event)}}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            classes={{list: className}} // We need this to apply main class to the modal menu which sits outside of the appBar header
        >
            { !MYACCOUNT_LIGHT &&
                <MenuItem>
                    <LinkItem to={MYACCOUNT_INBOX} classes={{root: "appBar-notification"}}>
                        <IconButton aria-label={`show ${pendingItemsCount} new notifications`} color="inherit">
                            <Badge badgeContent={pendingItemsCount} classes={{badge: "appBar-badge"}}>
                                <StarberryIcons iconName="notificationIcon" />
                            </Badge>
                        </IconButton>
                    </LinkItem>
                    <LinkItem to={MYACCOUNT_INBOX} color="inherit">Notifications</LinkItem>
                </MenuItem>
            }
            {/* <MenuItem onClick={handleProfileMenuOpen}> */}
            <MenuItem onClick={redirectToProfilePage}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <StarberryIcons iconName="accountIcon" />
                </IconButton>
                {/* <LinkItem to={MYACCOUNT_PROFILE} color="inherit">Profile</LinkItem> */}
                Profile
            </MenuItem>
            <MenuItem onClick={(event) => {handleLogout(event)}}>
                <IconButton aria-label="logout" color="inherit">
                    <StarberryIcons iconName="logoutIcon" />
                </IconButton>
                Logout
            </MenuItem>
        </Menu>
    );

    return(
        <div className={className}>
            <AppBar {...appBar} classes={{root: "appBar"}} {...rest}>
                <Toolbar classes={{root: "appBar-toolbar"}}>
                    {pageLeftIconState ? (
                        <IconButton onClick={goBack} classes={{root: "appBar-burgerButton"}} edge="start">
                            <StarberryIcons iconName="chevronLeftIcon" />
                        </IconButton>
                    ) : (
                        <IconButton
                            {...burgerButton}
                            onClick={handleDrawerToggle}
                            classes={{root: "appBar-burgerButton"}}
                        >
                            <DefineBurgerMenu {...burgerButtonIcon} />
                        </IconButton>
                    )}

                    <Typography {...appBarTitle} classes={{root: "appBar-appBarTitle"}}>{pageTitle || state?.pageTitle || ` `}</Typography>

                    {/* {WEBSITE_URL && (
                        <Box component="div" className="appBar-appBarSearch">
                            <Typography variant="body1" className={"back-to-site"}>
                                <strong><Link href={WEBSITE_URL}> <ArrowBackIcon /> Go to Website</Link></strong>
                            </Typography>
                        </Box>
                    )} */}
                    <div className={"appBar-grow"} />


                    <div className={"appBar-sectionDesktop"}>
                        { !MYACCOUNT_LIGHT &&
                            <LinkItem to={MYACCOUNT_INBOX} classes={{root: "appBar-notification"}}>
                                <IconButton aria-label={`show ${pendingItemsCount} new notifications`} color="inherit">
                                    <Badge badgeContent={pendingItemsCount} classes={{badge: "appBar-badge"}}>
                                        <StarberryIcons iconName="notificationIcon" />
                                    </Badge>
                                </IconButton>
                            </LinkItem>
                        }
                        <Button
                            aria-controls={menuId}
                            onClick={handleProfileMenuOpen}
                            endIcon={<StarberryIcons iconName="expandMoreIcon" />}
                            {...userDropdown}
                            classes={{
                                root: "appBar-userDropdown",
                                text: clsx("appBar-label", "appBar-text"),
                            }}
                        >
                            {user?.user?.name}
                        </Button>
                        {renderMenu}
                        {!!user?.user?.picture && (
                            <LinkItem to={MYACCOUNT_PROFILE} underline="none">
                                <UserAvatar alt={user?.user?.name} src={user?.user?.picture} classes={{root: "appBar-userAvatar"}} />
                            </LinkItem>
                        )}
                    </div>
                    <div className={"appBar-sectionMobile"}>
                        {pageRightDrawerContent ? (
                            <>
                                <SidebarDrawerComponent>
                                    {pageRightDrawerContent}
                                </SidebarDrawerComponent>
                            </>
                        ) : pageRightCutomComponentContent ? (
                            <>
                                {pageRightCutomComponentContent}
                            </>
                        ) : (
                            <>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                    edge="end"
                                >
                                    <StarberryIcons iconName="moreIcon" />
                                </IconButton>
                                {renderMobileMenu}
                            </>
                        )}
                    </div>
                </Toolbar>
            </AppBar>

            <div className={"appBar-sectionMobile"}>
                <AppBar
                    classes={{root: "appBar-appBarMobile"}}
                    {...appBarMobile}
                >
                    <Toolbar classes={{root: "appBar-toolbar"}}>
                        {menusBottomFixed.map((linkName) => (
                            <DefineIconMenu
                                key={linkName.id}
                                id={linkName.id}
                                text={false}
                                iconName={linkName.icon.name}
                                url={linkName.link}
                                hide={linkName.hide}
                                newtab={linkName.newtab || false}
                                funcname={linkName?.funcName || null}
                                handleDrawerToggle={handleDrawerToggle}
                                menuListItem={menuListItemMobile}
                                classesMenuListItem="appBar-menuListItemMobile"
                                classesMenuListItemIcon="appBar-menuListItemIconMobile"
                            />
                        ))}
                    </Toolbar>
                </AppBar>
            </div>

            <LogoutDialog open={open} handleConfirm={() => services.logout()} handleCancel={setOpen} />
        </div>
    )
}, 'appBar', defaults)

export default React.memo(AppBarComp);
