import React from 'react';
import { Link as GatsbyLink } from "gatsby";
import ListItem from '@mui/material/ListItem';
import LinkMui from '@mui/material/Link';
import ButtonMui from '@mui/material/Button';

// Define Gatsby Link component to use MUI style and props
const LinkListItem = React.forwardRef(function Link(props, ref) {
    return <ListItem
        activeClassName="Mui-selected"
        // partiallyActive={true}
        component={GatsbyLink}
        ref={ref}
        {...props}
    />;
});

const LinkItem = React.forwardRef(function Link(props, ref) {
    return <LinkMui
        activeClassName="Mui-selected"
        // partiallyActive={true}
        component={GatsbyLink}
        ref={ref}
        {...props}
    />;
});

const ButtonItem = React.forwardRef(function Link(props, ref) {
    return <ButtonMui
        activeClassName="Mui-selected"
        // partiallyActive={true}
        component={GatsbyLink}
        ref={ref}
        {...props}
    />;
});

export { LinkListItem, LinkItem, ButtonItem }
