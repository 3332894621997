export default {
    "props": {
        "drawerMobileHidden": {
            "mdDown": true,
            "implementation": "css", // The implementation can be swapped with js to avoid SEO duplication of links.
        },
        "drawerMobile": {
            "variant": "permanent",
            "anchor": "left",
            // "anchor": "right",
            "open": true,
        },
        "drawerDesktopHidden": {
            "mdUp": true,
            "implementation": "css", // The implementation can be swapped with js to avoid SEO duplication of links.
        },
        "drawerDesktop": {
            "variant": "temporary",
            // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            "anchor": "left",
            // "anchor": "right",
        },
        "menus": [
            {
                "id": 1,
                "hide": false,
                "name": "Dashboard",
                "icon": {
                    "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                    "props": {
                        "color": "primary",
                        "fontSize": "small",
                    },
                },
                "link": "/",
            },
            // {
            //     "id": 2,
            //     "hide": false,
            //     "name": "Dashboard2",
            //     "icon": {
            //         "name": "Home", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
            //         "props": {
            //             "color": "primary",
            //             "fontSize": "small",
            //         },
            //     },
            //     "link": "/index2",
            // },
            {
                "id": 3,
                "hide": false,
                "name": "My Property",
                "icon": {
                    "name": "myPropertyIcon",
                },
                "link": "/my-property",
            },
            {
                "id": 4,
                "hide": false,
                "name": "Inbox",
                "icon": {
                    "name": "inboxIcon",
                },
                "link": "/inbox",
            },
            {
                "id": 5,
                "hide": false,
                "name": "Calendar",
                "icon": {
                    "name": "calendarIcon",
                },
                "link": "/calendar",
            },
            {
                "id": 6,
                "hide": false,
                "name": "Profile",
                "icon": {
                    "name": "profileIcon",
                },
                "link": "/profile",
            },
            {
                "id": 7,
                "hide": true,
                "name": "Settings",
                "icon": {
                    "name": "settingsIcon",
                },
                "link": "#",
            },
            {
                "id": 8,
                "hide": false,
                "name": "Log out",
                "icon": {
                    "name": "logoutIcon",
                },
                "link": "#",
                "funcName": "logout",
            },
        ],
        "menuListItem": {
            "button": true,
            "disableRipple": true,
            // "divider": true,
        },
    },
    "sx": (theme) => ({
        "& .sidebarDrawer-drawerCommon-root": {
            "display": 'flex',
            "minHeight": "100vh",
        },
        "& .sidebarDrawer-drawerCommon-drawer": {
            [theme.breakpoints.up('md')]: {
                "width": `${theme.myAccountSetDrawerWidth}px`,
                "flexShrink": 0,
            },
            [theme.breakpoints.up('xl')]: {
                "width": `${theme.myAccountSetDrawerWidthXL}px`
            },
        },
        "& .sidebarDrawer-drawerCommon-drawerPaper": {
            "width": `${theme.myAccountSetDrawerWidth}px`,
            "backgroundColor": theme.palette.primary.main,
            "color": theme.palette.primary.contrastText,
            [theme.breakpoints.up('xl')]: {
                "width": `${theme.myAccountSetDrawerWidthXL}px`
            },
        },
        "& .sidebarDrawer-menuList": {
            "padding": theme.spacing(0,4),
            [theme.breakpoints.up('lg')]: {
                "padding": theme.spacing(0,8),
            },
            "& .sidebarDrawer-menuListItem": {
                "padding": theme.spacing(1.5,0),
                "opacity": 0.7,
                "background": "none",
                "&.Mui-selected": {
                    "opacity": 1,
                    "background": "none",
                },
                "&.Mui-selected:hover": {
                    "opacity": 1,
                    "background": "none",
                },
                "&:hover": {
                    "opacity": 1,
                    "background": "none",
                },
                "& .sidebarDrawer-menuListItemText": {
                    '& span':{
                        "fontWeight": 700,
                    }
                },
                "& .sidebarDrawer-menuListItemTextPrimary": {
                },
            },
            "& .sidebarDrawer-menuListItemIcon": {
                "minWidth": "40px",
                "color": theme.palette.primary.contrastText,
            },
        },
    }),
}