import React from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
// icons for sidebar
import { StarberryIcons } from '@myaccount/icons';

// My Account
import { useAuthState } from "@myaccountServices";
import { LinkListItem } from '@myaccount/helper/myaccountLink';
import LogoutDialog from '@myaccount/user/logoutDialog';

const DefineIconMenu = (props) => {
    const {
        iconName,
        id,
        text,
        url,
        hide,
        newtab,
        menuListItem,
        classesMenuListItem,
        classesMenuListItemIcon,
        handleDrawerToggle,
        ...rest
    } = props;

    const { services } = useAuthState();
    const [open, setOpen] = React.useState(false);

    // building target
    const target = newtab ? {"target": '_blank'} : {}

    let func = () => {};
    switch (rest?.funcname) {
        case 'logout':
            func = ( event ) => {
                event.preventDefault();
                // services.logout();
                setOpen(true);
            }
            break;
        case 'menu':
            func = ( event ) => {
                event.preventDefault();
                handleDrawerToggle();
            }
            break;
        default:
            func();
            break;
    }

    return (
        <React.Fragment>
            {!hide &&
                <LinkListItem
                    classes={{
                        root: classesMenuListItem,
                        divider: ".appBar-menuListItemMobile-divider"
                    }}
                    to={url}
                    onClick={(event) => func(event)}
                    {...target}
                    {...menuListItem}
                >
                    {iconName && <ListItemIcon classes={{root: classesMenuListItemIcon}}><StarberryIcons iconName={iconName} /></ListItemIcon>}
                    {text &&
                        <ListItemText
                            primary={text}
                            classes={{
                                root: "sidebarDrawer-menuListItemText",
                                primary: "sidebarDrawer-menuListItemTextPrimary",
                            }}
                        />
                    }
                </LinkListItem>
            }

            <LogoutDialog open={open} handleConfirm={() => services.logout()} handleCancel={setOpen} />
        </React.Fragment>
    )
}

export default DefineIconMenu
