import * as React from 'react';
import clsx from 'clsx';
import { withSubtheme } from '@starberryComponentsMui';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';

import defaults from './defaults';

const SidebarDrawerComponent = withSubtheme(props => {
    const {
        className,
        children,
        openDirection,
        setAnchor,
        listContainer
    } = props;

    const anchor = openDirection ? openDirection : setAnchor;

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx("customSidebarDrawer-list", {
                "customSidebarDrawer-fullList": anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <>
                {children}
            </>
        </div>
    );

    return (
        <div {...listContainer}>
            <IconButton onClick={toggleDrawer(anchor, true)} color="inherit" edge="end">
                <MoreIcon />
            </IconButton>
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                classes={{
                    root: className,
                    paper: "customSidebarDrawer-paper",
                }}
            >
                {list(anchor)}
            </Drawer>
        </div>
    );
}, 'customSidebarDrawer', defaults)

export default SidebarDrawerComponent
