import React from "react"
import { withSubtheme } from '@starberryComponentsMui';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material"
import defaults from './defaults';

const LogoutDialog = withSubtheme(props => {
    const { open, handleConfirm, handleCancel, modalTitle, modalSubTitle, dialogContentProps } = props;
    return (
        <Dialog
            open={open}
            onClose={() => handleCancel(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
            <DialogContent {...dialogContentProps}>
                <DialogContentText className="alert-dialog-description">
                    {modalSubTitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCancel(false)}>No</Button>
                <Button onClick={handleConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}, 'dialogLogout', defaults)

export default LogoutDialog
